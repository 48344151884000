import { Component } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatSnackBar, MatSnackBarHorizontalPosition, MatSnackBarVerticalPosition } from '@angular/material/snack-bar';
import { BackendService } from '../shared/services/backend.service';
import { HttpErrorResponse } from '@angular/common/http';
import { catchError, throwError } from 'rxjs';

@Component({
  selector: 'app-reset-password',
  templateUrl: './reset-password.component.html',
  styleUrls: ['./reset-password.component.scss']
})
export class ResetPasswordComponent {
  resetPasswordForm = new FormGroup({
    username: new FormControl('', Validators.required),
    password: new FormControl('', Validators.required),
    retypePassword: new FormControl('', Validators.required)
  });

  resetPasswordFormSubmitted = false;
  horizontalPosition: MatSnackBarHorizontalPosition = 'end';
  verticalPosition: MatSnackBarVerticalPosition = 'top';
  isSuccess: boolean = false;

  constructor(
    private backendService: BackendService,
    private snackBar: MatSnackBar
  ) {};

  onSubmit(){
    if(!this.resetPasswordForm.valid) {
      return;
    }

    this.resetPassword();
  }

  resetPassword() {
    this.resetPasswordFormSubmitted = true;

    this.backendService.resetPassword({
      username: this.resetPasswordForm.value['username']!,
      password: this.resetPasswordForm.value['password']!,
      retype_password: this.resetPasswordForm.value['retypePassword']!
    })
    .pipe(
      catchError((error: HttpErrorResponse) => this.handleError(error))
    )
    .subscribe({
      next: () => {
        this.isSuccess = true;

        this.resetPasswordForm.reset();
        this.snackBar.open('Password reset was successful!!', 'Dismiss', {
          duration: 5000,
        });
      },
      error: () => {
        this.isSuccess = false;
        this.resetPasswordFormSubmitted = false;
      }
    });
  }

  handleError(error: HttpErrorResponse) {
    if(error.status == 400) {
      this.snackBar.open('Invalid Request - ' + error.error['message'], 'Dismiss', {
        duration: 5000,
      });
    }
    else if(error.status == 401 || error.status == 403){
      this.snackBar.open(error.error['message'], 'Dismiss', {
        duration: 5000,
      });
    }
    else {
      this.snackBar.open('Internal Error. Please contact the admin.', 'Dismiss', {
        duration: 5000,
      });
    }

    return throwError(() => error);
  }
}
