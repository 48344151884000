import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { environment } from "src/environments/environment";
import { ResetPasswordRequest } from "../models/external/reset-password-request";

@Injectable({ providedIn: 'root' })
export class BackendService {
    constructor(
        private http: HttpClient
    ) {}

    resetPassword(resetPasswordRequestBody: ResetPasswordRequest) {
        const url = this.createUrl(environment.RESET_PASSWORD_ENDPOINT);
        
        return this.http.post(
            url,
            resetPasswordRequestBody
        );
    }

    private createUrl(baseUrl: string, username = '') {
        let url = baseUrl.replace('{user}', username ?? '');

        return url;
    }
}