<div class="success-container" *ngIf="isSuccess">
    <mat-icon color="primary" class="success-icon">check_circle</mat-icon>
    <h1>Success</h1>
    <p>Your password was reset successfully!</p>
</div>
<div *ngIf="!isSuccess">
    <h2>Reset Password</h2>
    <div class="reset-password-page-container">
        <div class="reset-password-form-container">
            <form [formGroup]="resetPasswordForm" (submit)="onSubmit()" class="reset-password-form">
                <mat-form-field appearance="outline">
                    <mat-label>Username</mat-label>
                    <input matInput formControlName="username">
                    <mat-error
                        *ngIf="resetPasswordForm.invalid && (resetPasswordForm.dirty || resetPasswordForm.touched)">
                        Username is required
                    </mat-error>
                </mat-form-field>

                <mat-form-field appearance="outline">
                    <mat-label>Password</mat-label>
                    <input matInput formControlName="password" type="password">
                    <mat-error
                        *ngIf="resetPasswordForm.invalid && (resetPasswordForm.dirty || resetPasswordForm.touched)">
                        Password is required
                    </mat-error>
                </mat-form-field>

                <mat-form-field appearance="outline">
                    <mat-label>Re-type Password</mat-label>
                    <input matInput formControlName="retypePassword" type="password">
                    <mat-error
                        *ngIf="resetPasswordForm.invalid && (resetPasswordForm.dirty || resetPasswordForm.touched)">
                        Re-Type Password is required
                    </mat-error>
                </mat-form-field>

                <button
                    mat-flat-button color="primary"
                    class="reset-password-button"
                    [disabled]="resetPasswordForm.invalid || resetPasswordFormSubmitted"
                    >
                        <span *ngIf="!resetPasswordFormSubmitted">RESET PASSWORD</span>
                        <span *ngIf="resetPasswordFormSubmitted">
                            <mat-spinner [diameter]="40"></mat-spinner>
                        </span>
                </button>

                <div class="help-text">
                    <mat-icon>info</mat-icon>
                    <span>MFA for the user should be enabled for resetting the password</span>
                </div>

                <div class="password-policy-text">
                    <span>Password Policy - <br><br> 1. Minimum Length - 8 <br> 2. Uppercase Required <br> 3. Numbers Required <br> 4. Symbols Required</span>
                </div>
            </form>
        </div>

        <div class="lion-graphic-container">
            <img src="assets/lion-graphic.png" alt="lion graphic image" />
        </div>
    </div>
</div>
